import React from "react";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faTelegram } from '@fortawesome/free-brands-svg-icons'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { Disclosure } from "@headlessui/react"
import Hamburger from 'hamburger-react'
import { useWallet } from "@utils/walletHelper";
import logo from '@images/logo.png'

const Header = () => {
  return (
    <div className="flex justify-center items-center top-0 z-50 w-full px-6 relative pt-6">
      <img src={logo} alt="logo image" className="h-7" />
    </div>
  )
}

export default Header;
