import React, { useEffect, useState } from "react";
import { Snackbar, Alert } from '@mui/material';
import { useWallet } from "@utils/walletHelper";
import Layout from '@pages/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faWarning } from '@fortawesome/free-solid-svg-icons'
import { fetchNFT, claimNFT } from '@utils/contracts';
import info from './data.json';
import img_bigBox from '@images/big-box.png'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faTelegram } from '@fortawesome/free-brands-svg-icons'

const Home = () => {
  return (
    <Layout title="Important Notice | Lazarus Pit">
      <div className="container mx-auto">
          <div className="relative w-full md:w-4/5 xl:w-2/3 mx-auto mt-8 px-4" style={{ paddingBottom: '4rem' }}>
              <img src={img_bigBox} className="min-h-[800px] sm:min-h-[800px]" />

              {/* Banner for Important News */}
              <div className="absolute w-full top-0 left-0 px-16">
                  <div className="bg-purple-light text-white sm:text-xl uppercase py-2 rounded-md text-center">
                      IMPORTANT NOTICE
                  </div>
              </div>

              {/* Text Content */}
              <div className="absolute top-[4rem] left-1/2 -translate-x-1/2 mt-8 w-full px-16">
                  <div className="text-purple-light uppercase sm:text-2xl text-center space-y-4 w-full">
                      <p>Lazarus Pit has officially been acquired by Monstro's Universe.</p>
                      <p>All NFTs have been migrated to BNB Chain to facilitate participation in perks and privileges.</p>
                      <p>The current plan is that all NFT holders will receive MONSTRO tokens at a 1:1 ratio against their shares when the token launches in 2024. We believe this will give all holders not only a chance at a quicker recovery, but full flexibility to do with their tokens as they like at any time they like.</p>
                      <p>For further information, we invite you to join the Monstro's Universe Discord or Telegram servers.</p>
                      <p>&nbsp;</p>

                      {/* Buttons Container */}
                      <div className="flex justify-center gap-4">
                          <a
                              href="https://discord.gg/monstros"
                              target="_blank"
                              rel="noreferrer"
                              className="flex justify-center items-center bg-purple-light rounded-xl cursor-pointer hover:scale-110 transform duration-200 px-4 py-3"
                          >
                              <FontAwesomeIcon icon={faDiscord} className="text-white text-large" />
                          </a>
                          <a
                              href="https://t.me/monstrosU"
                              target="_blank"
                              rel="noreferrer"
                              className="flex justify-center items-center bg-purple-light rounded-xl cursor-pointer hover:scale-110 transform duration-200 px-4 py-3"
                          >
                              <FontAwesomeIcon icon={faTelegram} className="text-white text-large" />
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </Layout>
  )
}

export default Home;
