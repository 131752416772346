import { Helmet } from "react-helmet"
import Header from './Header'

const Layout = ({ children, title="" }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header/>
      { children }
    </>
  )
}

export default Layout;